import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type DtoProgram,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { type Organization } from '../../types';
import { assertExhaustive } from '../../utils/common';
import {
  type SlackModalBinding,
  useCheckSlackScopes,
  useTriggerSlackAppUpgradeModal,
  useTriggerSlackChannelBindingModal,
} from '../Channel';
import { CalendarUseProgramButton } from './Calendar';
import { ProgramKnifeFactory } from './ProgramKnifes';
import { RecognitionUseProgramButton } from './Recognition';
import { ProgramUtils } from './utils';

function ReconnectSlackButton(props: UseProgramButtonProps) {
  const triggerSlackAppUpgradeModal = useTriggerSlackAppUpgradeModal();

  return (
    <button
      type='button'
      className={props.className ?? 'btn-primary w-40 h-10 disabled:opacity-80'}
      onClick={() => triggerSlackAppUpgradeModal(window.location.href)}
    >
      Reconnect Slack
    </button>
  );
}

function StartSetupButton(props: UseProgramButtonProps) {
  const { org, program, bindingNewChannel } = props;
  const navigate = useNavigate();
  const triggerSlackChannelBindingModal = useTriggerSlackChannelBindingModal();

  const handleClick = () => {
    props.onClick?.();

    triggerSlackChannelBindingModal({
      orgId: org.id,
      program,
      overrideStepConfig: {
        bindingNewModal: {
          channelName: bindingNewChannel,
        },
        bindingDoneModal: {
          buttonText: 'Configure Program',
        },
      },
      onComplete: async (binding: SlackModalBinding) => {
        if (binding.programLink) {
          navigate(ProgramUtils.BuildURL(binding.programLink));
        } else {
          navigate('/channels');
        }
      },
    });
  };

  return (
    <button
      type='button'
      className={props.className ?? 'btn-primary w-40 h-10'}
      onClick={handleClick}
    >
      Start Setup
    </button>
  );
}

export type UseProgramButtonProps = {
  org: Organization;
  program: DtoProgram;
  onClick?: () => void;
  bindingNewChannel?: string;
  className?: string;
};

function DefaultUseProgramButton(props: UseProgramButtonProps) {
  const extraScopes = useMemo(() => {
    return ProgramKnifeFactory[props.program.type].ExtraSlackScopes();
  }, [props.program.type]);
  const upToDate = useCheckSlackScopes(...extraScopes);

  if (!upToDate) return <ReconnectSlackButton {...props} />;
  return <StartSetupButton {...props} />;
}

export function UseProgramButton(props: UseProgramButtonProps) {
  const { program } = props;

  const forceInstall = useFeatureQueryParam('program-force-install');

  if (!forceInstall && program.preparing) {
    return (
      <button type='button' className='btn-primary w-40 h-10' disabled>
        Coming Soon
      </button>
    );
  }

  switch (program.type) {
    case EnumsProgramType.ProgramTypeCalendar:
      return <CalendarUseProgramButton {...props} />;
    case EnumsProgramType.ProgramTypeRecognition:
      return <RecognitionUseProgramButton {...props} />;
    case EnumsProgramType.ProgramTypeIntros:
      return (
        <DefaultUseProgramButton
          {...props}
          bindingNewChannel='lunapark-intros'
        />
      );
    case EnumsProgramType.ProgramTypeWaterCooler:
      return (
        <DefaultUseProgramButton
          {...props}
          bindingNewChannel='lunapark-watercoolers'
        />
      );
    case EnumsProgramType.ProgramTypeGlobalPairing:
    case EnumsProgramType.ProgramTypeGameDrops:
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return <DefaultUseProgramButton {...props} />;
    case EnumsProgramType.ProgramTypeDummy:
    case EnumsProgramType.ProgramTypeAiSurvey:
    case EnumsProgramType.ProgramTypeCustomTournament:
      return null;
    default:
      assertExhaustive(program.type);
      return null;
  }
}
