import useSWR, { type SWRResponse } from 'swr';

import { type DtoChannel } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';

export function useChannels(orgId: string): SWRResponse<DtoChannel[], unknown> {
  return useSWR<DtoChannel[], unknown>(`/channels?orgId=${orgId}`, async () => {
    const resp = await apiService.channel.queryChannels(orgId);
    return resp.data.channels;
  });
}
