import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  type DtoProgram,
  type DtoRecognitionProgramLinkExtensions,
  EnumsExConnectType,
  EnumsProgramLinkStatus,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import lpLogo from '../../../assets/img/lp-3d-logo.png';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { err2s } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import {
  useCheckSlackScopes,
  useTriggerSlackAppUpgradeModal,
} from '../../Channel';
import { useChannels } from '../../Channel/hooks/useChannels';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { CloseIcon } from '../../icons/CloseIcon';
import { Loading } from '../../Loading';
import { SlackChannelPicker } from '../../Slack';
import { ProgramUtils } from '../utils';
import { type EmojiOption, RecognitionProgramUtils } from './utils';

type FormData = DtoRecognitionProgramLinkExtensions;

function InitRecognitionModal(props: {
  org: Organization;
  channel: DtoChannel;
  program: DtoProgram;
  defaultValue: string;
  onConfirm: (link: DtoChannelProgramLink) => void;
  onClose: () => void;
}): JSX.Element {
  const { channel, program } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<EmojiOption>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const form = useForm<FormData>({
    defaultValues: {
      emoji: RecognitionProgramUtils.ValueToEmojiOption(props.defaultValue),
      membersDailyGivingAmount: 5,
      adminDailyGivingAmount: 5,
    },
  });

  const options = useInstance(() => RecognitionProgramUtils.GetEmojiOptions());

  const {
    call: createProgramLink,
    state: { state, error },
  } = useLiveAsyncCall(async (data: FormData) => {
    const extensions: DtoRecognitionProgramLinkExtensions = {
      membersDailyGivingAmount: data.membersDailyGivingAmount,
      adminDailyGivingAmount: data.adminDailyGivingAmount,
      initExGroupId: data.initExGroupId,
    };
    if (data.emoji) {
      extensions.emoji = { ...data.emoji, default: true };
    }
    const resp = await apiService.channel.addProgramLink(channel.id, {
      extensions: extensions,
      programId: program.id,
      programTargetId: program.id,
      programType: EnumsProgramType.ProgramTypeRecognition,
    });
    return resp.data.programLink;
  });

  const onSubmit = () => {
    form.handleSubmit(async (data: FormData) => {
      const link = await createProgramLink(data);
      if (!link) return;
      props.onConfirm(link);
    })();
  };

  return (
    <ModalWrapper containerClassName='w-172' borderStyle='gray'>
      <FormProvider {...form}>
        <div className='w-full h-full min-h-52 flex flex-col items-center px-12 py-10'>
          <button
            type='button'
            className='btn absolute right-2 top-2 bg-transparent w-6 h-6 
      flex items-center justify-center text-secondary hover:text-white'
            onClick={props.onClose}
          >
            <CloseIcon className='w-3 h-3 fill-current' />
          </button>
          <header className='font-medium text-2xl flex flex-col items-center'>
            <img src={lpLogo} alt='Luna Park' className='mb-4 w-44 h-18' />
          </header>
          <main className='w-full flex flex-col items-center gap-6'>
            <div className='text-2xl font-medium'>
              Setup Ice Cream Shout-out Program
            </div>

            <Controller
              name='emoji'
              control={form.control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <div className='w-full flex flex-col gap-1'>
                  <label className='font-bold'>Emoji Trigger</label>
                  <Select<EmojiOption>
                    options={options}
                    onChange={(o) => onChange(o)}
                    value={options.find((o) => o.value === value?.value)}
                    classNamePrefix='select-box-v2'
                    className='w-full h-13.5'
                    styles={styles}
                    getOptionLabel={(option) =>
                      `${option.token} ${option.name}`
                    }
                  />
                </div>
              )}
            />

            <Controller
              name='initExGroupId'
              control={form.control}
              rules={{ required: true }}
              render={({ field: { onChange }, fieldState }) => (
                <div className='w-full flex-col gap-1'>
                  <label className='font-bold'>
                    Select an Existing Channel
                  </label>
                  <div className='w-full h-13.5'>
                    <SlackChannelPicker
                      orgId={props.org.id}
                      types={'public'}
                      onChange={(channel) => onChange(channel.id)}
                      error={fieldState.error}
                    />
                  </div>
                </div>
              )}
            />

            <div className='flex flex-col items-center'>
              <button
                type='button'
                className='btn-primary w-40 h-10 flex items-center justify-center gap-1'
                disabled={state.isRunning}
                onClick={onSubmit}
              >
                {state.isRunning && <Loading text='' />}
                <div>Continue</div>
              </button>
              {error && (
                <div className='text-red-002 text-sms'>{err2s(error)}</div>
              )}
            </div>
          </main>
        </div>
      </FormProvider>
    </ModalWrapper>
  );
}

export function RecognitionUseProgramButton(props: {
  org: Organization;
  program: DtoProgram;
  onClick?: () => void;
  className?: string;
}) {
  const { org, program } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();
  const swr = useChannels(org.id);
  const isSlackAppUpToDate = useCheckSlackScopes(
    'im:history',
    'mpim:history',
    'groups:history',
    'channels:history',
    'reactions:read'
  );
  const channels = swr.data ?? [];

  const channel = channels.find(
    (c) => c.exConnectType === EnumsExConnectType.ExConnectTypeLpOrgAllMembers
  );

  const link = channel?.programLinks?.find(
    (link) => link.programType === program.type
  );

  const activated =
    link?.status === EnumsProgramLinkStatus.ProgramLinkStatusActive;

  const {
    call: activateLink,
    state: { state, error },
  } = useLiveAsyncCall(async (link: DtoChannelProgramLink) => {
    return await apiService.channel.activateProgramLink(
      link.channelId,
      link.id
    );
  });

  const isLoading = swr.isLoading || isSlackAppUpToDate === 'unknown';

  const triggerSlackAppUpgradeModal = useTriggerSlackAppUpgradeModal();

  const triggerInitRecognitionModal = async () => {
    if (!channel) return;
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <InitRecognitionModal
          org={org}
          channel={channel}
          program={program}
          defaultValue='icecream'
          onConfirm={async (link) => {
            p.internalOnConfirm();
            navigate(ProgramUtils.BuildURL(link));
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  const onClick = async () => {
    if (!channel || isLoading) return;
    if (!isSlackAppUpToDate) {
      await triggerSlackAppUpgradeModal();
      props.onClick?.();
      return;
    }
    if (!link) {
      triggerInitRecognitionModal();
      props.onClick?.();
      return;
    }
    const resp = await activateLink(link);
    props.onClick?.();
    if (!resp) return;
    navigate(ProgramUtils.BuildURL(link));
  };

  return (
    <div>
      <button
        type='button'
        className={
          props.className ?? 'btn-primary w-40 h-10 disabled:opacity-80'
        }
        disabled={isLoading || activated || state.isRunning}
        onClick={onClick}
      >
        {isLoading ? (
          <Loading />
        ) : activated ? (
          'Already Activated'
        ) : !channel ? (
          'Not Available'
        ) : isSlackAppUpToDate ? (
          state.isRunning ? (
            <Loading text='Activating' />
          ) : (
            'Start Setup'
          )
        ) : (
          'Reconnect Slack'
        )}
      </button>
      {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
    </div>
  );
}
