export type EmojiOption = { token: string; name: string; value: string };
export class RecognitionProgramUtils {
  static GetEmojiOptions(): readonly EmojiOption[] {
    return EMOJI_OPTIONS.sort((a, b) => a.name.localeCompare(b.name));
  }

  static ValueToEmojiOption(value: string) {
    return EMOJI_OPTIONS.find((x) => x.value === value);
  }
}

const EMOJI_OPTIONS: EmojiOption[] = [
  { token: '💪', name: 'Muscle', value: 'muscle' },
  { token: '🚀', name: 'Rocket', value: 'rocket' },
  { token: '👏', name: 'Clap', value: 'clap' },
  { token: '🤩', name: 'Start Struck', value: 'star-struck' },
  { token: '🌮', name: 'Taco', value: 'taco' },
  { token: '💩', name: 'Hankey', value: 'hankey' },
  { token: '🧡', name: 'Orange Heart', value: 'orange-heart' },
  { token: '💣', name: 'Bomb', value: 'bomb' },
  { token: '🦄', name: 'Unicorn Face', value: 'unicorn_face' },
  { token: '🐙', name: 'Octopus', value: 'octopus' },
  { token: '🍔', name: 'Hamburger', value: 'hamburger' },
  { token: '🍕', name: 'Pizza', value: 'pizza' },
  { token: '🍄', name: 'Mushroom', value: 'mushroom' },
  { token: '🌶️', name: 'Hot Pepper', value: 'hot_pepper' },
  { token: '🍦', name: 'Icecream', value: 'icecream' },
  { token: '🥟', name: 'Dumpling', value: 'dumpling' },
  { token: '🍭', name: 'Lollipop', value: 'lollipop' },
  { token: '🌭', name: 'Hotdog', value: 'hotdog' },
  { token: '🍬', name: 'Candy', value: 'candy' },
  { token: '⭐️', name: 'Star', value: 'star' },
  { token: '🌈', name: 'Rainbow', value: 'rainbow' },
  { token: '🎈', name: 'Balloon', value: 'balloon' },
  { token: '🧨', name: 'Firecracker', value: 'firecracker' },
  { token: '🎁', name: 'Gift', value: 'gift' },
  { token: '🎖️', name: 'Medal', value: 'medal' },
  { token: '🏆', name: 'Trophy', value: 'trophy' },
  { token: '🏅', name: 'Sports Medal', value: 'sports_medal' },
  { token: '💰', name: 'Money Bag', value: 'moneybag' },
  { token: '🎡', name: 'Ferris Wheel', value: 'ferris_wheel' },
  { token: '🏹', name: 'Bow and Arrow', value: 'bow_and_arrow' },
];
