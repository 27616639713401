import { useNavigate } from '@remix-run/react';

import {
  type DtoChannelProgramLink,
  type DtoProgram,
  EnumsProgramLinkStatus,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { err2s } from '../../../utils/common';
import { useChannels } from '../../Channel/hooks/useChannels';
import { Loading } from '../../Loading';
import { ProgramUtils } from '../utils';

export function CalendarUseProgramButton(props: {
  org: Organization;
  program: DtoProgram;
  onClick?: () => void;
  className?: string;
}) {
  const { org, program } = props;
  const navigate = useNavigate();
  const { data, isLoading } = useChannels(org.id);
  const channels = data ?? [];

  const link = channels
    .flatMap((channel) => channel.programLinks)
    .find((link) => link.programType === program.type);

  const activated =
    link?.status === EnumsProgramLinkStatus.ProgramLinkStatusActive;

  const {
    call: activateLink,
    state: { state, error },
  } = useLiveAsyncCall(async (link: DtoChannelProgramLink) => {
    return await apiService.channel.activateProgramLink(
      link.channelId,
      link.id
    );
  });

  const onClick = async () => {
    if (!link) {
      props.onClick?.();
      return;
    }
    const resp = await activateLink(link);
    props.onClick?.();
    if (!resp) return;
    navigate(ProgramUtils.BuildURL(link));
  };

  return (
    <div>
      <button
        type='button'
        className={
          props.className ?? 'btn-primary w-40 h-10 disabled:opacity-80'
        }
        disabled={isLoading || activated || state.isRunning || !link}
        onClick={onClick}
      >
        {isLoading ? (
          <Loading />
        ) : activated ? (
          'Already Activated'
        ) : !link ? (
          'Not Available'
        ) : state.isRunning ? (
          <Loading text='Activating' />
        ) : (
          'Start Setup'
        )}
      </button>
      {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
    </div>
  );
}
