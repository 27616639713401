import copy from 'copy-to-clipboard';
import React, { useState } from 'react';

import {
  type DtoProgram,
  type DtoSlackUser,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import lpLogo from '../../../assets/img/lp-3d-logo.png';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type ShareMessageRequest } from '../../../services/api-service/message.api';
import { type SlackChannel } from '../../../types/slack';
import { assertExhaustive, err2s } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { CloseIcon } from '../../icons/CloseIcon';
import { SlackIcon } from '../../icons/SlackIcon';
import { Loading } from '../../Loading';
import { useOrgConnection } from '../../Organization';
import { type Program } from '../../Program';
import { getSlackBindingStepConfigOverride } from '../../Program/usePrograms';
import { SlackChannelPicker, SlackUserPicker, SlackUtils } from '../../Slack';
import { useSlackInstallation } from '../hooks/useInstallation';
import { useInviteUsers } from '../hooks/useInviteUsers';
import { ChannelUtils } from '../utils';
import {
  type SlackBindingModalStep,
  type SlackBindingStepConfig,
  type SlackBindingStepConfigOverride,
  type SlackModalBinding,
} from './config';
import { SlackMessageInput, SlackMessagePreview } from './SlackMessage';

function CloseButton(props: { onClick: () => void }): JSX.Element {
  return (
    <button
      type='button'
      className='btn absolute right-2 top-2 bg-transparent w-6 h-6 
      flex items-center justify-center text-secondary hover:text-white'
      onClick={props.onClick}
    >
      <CloseIcon className='w-3 h-3 fill-current' />
    </button>
  );
}

function ModalLayout(props: {
  title: React.ReactNode;
  children?: React.ReactNode;
  onClose: () => void;
}) {
  const { title, children } = props;

  return (
    <ModalWrapper containerClassName='w-172' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center px-12 py-10'>
        <CloseButton onClick={props.onClose} />
        <header className='font-medium text-2xl flex flex-col items-center'>
          <img src={lpLogo} alt='Luna Park' className='mb-4 w-44 h-18' />
          <div className='flex items-center justify-center'>
            <SlackIcon className='w-7.5 h-7.5 mr-5' />
            <div>{title}</div>
          </div>
        </header>

        {children}
      </div>
    </ModalWrapper>
  );
}

interface ModalSharedProps {
  orgId: string;
  program: Program;
  stepConfig: SlackBindingStepConfig;
  setStep: (step: SlackBindingModalStep) => void;
  onClose: () => void;
  onComplete: (binding: SlackModalBinding) => void;

  binding: SlackModalBinding;
  updateBinding: (updates: Partial<SlackModalBinding>) => void;
}

function BindingNewModal(props: ModalSharedProps): JSX.Element {
  const [name, setName] = useState(
    props.stepConfig.bindingNewModal.channelName
  );

  const handleUpdateChannelName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const normalized = event.target.value
      .toLowerCase()
      .replace(' ', '-')
      .replace(/[^a-zA-Z0-9/-]+/, '');
    setName(normalized);
  };

  const {
    call: handleCreateChannel,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    const { slackChannel, channel, programLink } =
      await ChannelUtils.InstallProgramInNewChannel({
        orgId: props.orgId,
        channelName: name,
        program: props.program,
      });

    props.updateBinding({
      slackChannel,
      channel,
      programLink,
    });
    props.setStep('invite-users');
  });

  return (
    <ModalLayout
      title={props.stepConfig.bindingNewModal.title}
      onClose={props.onClose}
    >
      {props.stepConfig.bindingNewModal.description?.({
        program: props.program,
      })}

      <div className='relative w-full mt-10 mb-6'>
        <input
          className={`w-full ${error ? 'field-error' : 'field'} h-15 m-0 pl-6`}
          placeholder='enter-slack-channel-name'
          value={name}
          onChange={handleUpdateChannelName}
        />
        <div className='text-sms absolute left-[16px] top-[22px]'>#</div>
        {error && (
          <div className='mt-1 text-red-002 text-sms text-left'>
            {err2s(error)}
          </div>
        )}
      </div>

      <footer className='w-full flex items-center justify-center gap-4'>
        <button
          type='button'
          className='btn-secondary w-62 h-10 flex items-center justify-center'
          onClick={() => props.setStep('bind-exist')}
        >
          Use an existing Channel
        </button>
        <button
          type='button'
          className='btn-primary w-50 h-10 flex items-center justify-center'
          disabled={!name || isRunning}
          onClick={handleCreateChannel}
        >
          {isRunning && <Loading text='' />}
          <div className='ml-2'>Create Channel</div>
        </button>
      </footer>
    </ModalLayout>
  );
}

function BindingExistModal(props: ModalSharedProps): JSX.Element {
  const [slackChannel, setSlackChannel] = useState<SlackChannel | null>(null);

  const {
    call: handleBindChannel,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    if (!slackChannel) return;

    const resp = await ChannelUtils.InstallProgramInExistingChannel({
      orgId: props.orgId,
      slackChannel,
      program: props.program,
    });

    if (resp) {
      props.updateBinding({
        slackChannel,
        channel: resp.channel,
        newInvitees: resp.newInvitees,
        programLink: resp.programLink,
      });

      props.setStep('share-message');
    }
  });

  return (
    <ModalLayout
      title={props.stepConfig.bindingExistModal.title}
      onClose={props.onClose}
    >
      <div className='w-full h-15 mt-7'>
        <SlackChannelPicker
          orgId={props.orgId}
          types={'public'}
          onChange={(channel) => setSlackChannel(channel)}
          error={error}
        />
        {error && (
          <div className='mt-1 text-red-002 text-sms'>{err2s(error)}</div>
        )}
      </div>

      {props.stepConfig.bindingExistModal.description?.({
        program: props.program,
      })}

      <button
        type='button'
        onClick={() => props.setStep('bind-private')}
        className='mt-4 btn text-sms font-medium text-primary text-center'
      >
        Set up Program in a private Slack channel
      </button>

      <footer className='w-full flex items-center justify-center gap-4 mt-4'>
        <button
          type='button'
          className='btn-secondary px-7 min-w-40 h-10 flex items-center justify-center'
          onClick={() => props.setStep('bind-new')}
        >
          {props.stepConfig.bindingExistModal.backButtonText}
        </button>
        <button
          type='button'
          className='btn-primary w-50 h-10 flex items-center justify-center'
          disabled={!slackChannel || isRunning}
          onClick={handleBindChannel}
        >
          {isRunning && <Loading text='' />}
          <div className='ml-2'>Select Channel</div>
        </button>
      </footer>
    </ModalLayout>
  );
}

function RequireLatestSlackModal(props: {
  onClose: () => void;
  onBack?: () => void;
  updateLink?: string;
}) {
  const { onClose, onBack, updateLink = window.location.href } = props;

  const handleCopy = async () => {
    copy(updateLink);
  };

  const handleContinue = async () => {
    const url = await SlackUtils.GenerateSlackInstallURL({
      scenario: 'connect',
    });
    window.location.href = url;
  };

  return (
    <ModalLayout
      title='You need the latest version of the Luna Park Slack app to continue.'
      onClose={onClose}
    >
      <div className='w-full my-10 text-center font-bold max-w-125'>
        This requires Slack admin access. If you have access, click “Continue to
        Slack” below. If you don’t have access, you’ll need your Slack
        administrator to update the Luna Park Slack app before you can continue.{' '}
        <span onClick={handleCopy} className='btn text-primary cursor-pointer'>
          Copy Update Link
        </span>
      </div>
      <footer className='w-full flex items-center justify-center gap-4 mt-4'>
        {onBack && (
          <button
            type='button'
            className='btn-secondary px-7 min-w-40 h-10 flex items-center justify-center'
            onClick={onBack}
          >
            Back
          </button>
        )}

        <button
          type='button'
          className='btn-primary w-50 h-10 flex items-center justify-center'
          onClick={handleContinue}
        >
          Continue to Slack
        </button>
      </footer>
    </ModalLayout>
  );
}

export function useTriggerSlackAppUpgradeModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback(async (link?: string) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <RequireLatestSlackModal
          onClose={p.internalOnCancel}
          updateLink={link}
        />
      ),
    });
  });
}

function BindingPrivateModal(props: ModalSharedProps) {
  const { data: installation, isLoading } = useSlackInstallation();

  const [slackChannel, setSlackChannel] = useState<SlackChannel | null>(null);

  const {
    call: handleBindChannel,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    if (!slackChannel) return;

    const resp = await ChannelUtils.InstallProgramInExistingChannel({
      orgId: props.orgId,
      slackChannel,
      program: props.program,
    });

    if (resp) {
      props.updateBinding({
        slackChannel,
        channel: resp.channel,
        programLink: resp.programLink,
        newInvitees: resp.newInvitees,
      });
      props.setStep('share-message');
    }
  });

  if (isLoading) return <Loading text='' />;
  if (installation && !installation.scopes.includes('groups:read')) {
    return (
      <RequireLatestSlackModal
        onClose={props.onClose}
        onBack={() => props.setStep('bind-exist')}
      />
    );
  }

  return (
    <ModalLayout
      title='Set up Program in a private Slack channel'
      onClose={props.onClose}
    >
      <div className='w-full h-15 my-2'>
        <SlackChannelPicker
          orgId={props.orgId}
          types={'private'}
          onChange={(channel) => setSlackChannel(channel)}
          error={error}
        />
        {error && <div className='text-red-002 text-sms'>{err2s(error)}</div>}
      </div>

      <div className='mt-6 max-w-125 text-center text-sm font-bold'>
        Don’t see your private channel? Go to the Slack channel you’d like to
        set up the Program in, type “/invite @Luna Park”, and we’ll send you
        instructions in Slack.
      </div>

      <footer className='w-full flex items-center justify-center gap-4 mt-4'>
        <button
          type='button'
          className='btn-secondary px-7 min-w-40 h-10 flex items-center justify-center'
          onClick={() => props.setStep('bind-exist')}
        >
          Back
        </button>
        <button
          type='button'
          className='btn-primary w-50 h-10 flex items-center justify-center'
          disabled={!slackChannel || isRunning}
          onClick={handleBindChannel}
        >
          {isRunning && <Loading text='' />}
          <div className='ml-2'>Select Channel</div>
        </button>
      </footer>
    </ModalLayout>
  );
}

function BindingDoneModal(props: ModalSharedProps): JSX.Element | null {
  if (!props.stepConfig.bindingDoneModal.enabled) {
    props.onComplete(props.binding);
    return null;
  }

  return (
    <ModalLayout
      title={props.stepConfig.bindingDoneModal.title}
      onClose={props.onClose}
    >
      {props.stepConfig.bindingDoneModal.description?.({
        binding: props.binding,
      })}
      <footer className='w-full flex items-center justify-center gap-4'>
        <button
          type='button'
          className='btn-primary min-w-40 h-10 px-5 flex items-center justify-center'
          onClick={() => props.onComplete(props.binding)}
        >
          {props.stepConfig.bindingDoneModal.buttonText || 'Done'}
        </button>
      </footer>
    </ModalLayout>
  );
}

function InviteUserModal(props: ModalSharedProps): JSX.Element {
  const { connection } = useOrgConnection({
    orgId: props.orgId,
    immutable: true,
  });
  const inviteUsers = useInviteUsers();

  const [users, setUsers] = useState<DtoSlackUser[]>([]);

  const handlePickUsers = (users: DtoSlackUser[]) => {
    setUsers(users);
  };

  const {
    call: handleAddAll,
    state: {
      state: { isRunning: isAddingAll },
      error: addAllErr,
    },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.slack.queryUsers({
      type: 'all',
      orgId: props.orgId,
    });
    setUsers(resp.data.users);
  });

  const {
    call: handleInvite,
    state: {
      state: { isRunning: isInviting },
      error: inviteErr,
    },
  } = useLiveAsyncCall(async () => {
    if (!props.binding.channel) return;

    const newInvitees = await inviteUsers(
      props.orgId,
      props.binding.channel.id,
      users,
      {
        removeUnmatched: props.binding.inviteRemoveUnmatched,
      }
    );
    if (newInvitees === undefined) return;

    props.updateBinding({
      newInvitees,
    });

    props.setStep('share-message');
  });

  return (
    <ModalLayout
      title={`Invite people to ${props.binding.channel?.name}`}
      onClose={props.onClose}
    >
      <section className='w-full items-center my-5'>
        <div className='text-center text-sms'>
          {props.stepConfig.inviteUserModal.description?.({
            program: props.program,
          })}
        </div>
        <div className='mt-4'>
          <SlackUserPicker
            users={users}
            onChange={handlePickUsers}
            orgId={props.orgId}
          />
        </div>
        {users.length > 0 && (
          <div className='text-xs mt-1'>
            {users.length} people will be invited.
          </div>
        )}
        {connection && (
          <div className='flex items-start justify-center text-sms text-center mt-6 mb-4'>
            <button
              type='button'
              className='btn text-primary flex items-center justify-center'
              onClick={handleAddAll}
              disabled={isAddingAll}
            >
              <div>Add everyone from {connection?.name}’s Workspace</div>
              {isAddingAll && <Loading text='' imgClassName='w-4 h-4 ml-2' />}
            </button>
          </div>
        )}
      </section>

      {(inviteErr || addAllErr) && (
        <div className='text-red-002 text-sms mb-1'>
          {err2s(inviteErr || addAllErr)}
        </div>
      )}
      <footer className='w-full flex items-center justify-center gap-4'>
        <button
          type='button'
          className='btn-secondary w-45 h-10 flex items-center justify-center'
          onClick={() => props.setStep('share-message')}
        >
          Skip for now
        </button>
        <button
          type='button'
          className='btn-primary w-50 h-10 flex items-center justify-center'
          disabled={users.length === 0 || isInviting}
          onClick={handleInvite}
        >
          {isInviting && <Loading text='' />}
          <div className='ml-2'>Invite to Channel</div>
        </button>
      </footer>
    </ModalLayout>
  );
}

function ShareMessageModal(props: ModalSharedProps): JSX.Element | null {
  const {
    state: {
      state: { isRunning },
      error,
    },
    call,
  } = useLiveAsyncCall(async (req: ShareMessageRequest) => {
    return await apiService.message.shareMessage(req);
  });

  const [text, setText] = useState(
    props.stepConfig.shareMessageModal.initialText
  );
  const [media, setMedia] = useState<Media | null>(
    props.stepConfig.shareMessageModal.attachedMedia
  );

  const handleClickShare = async () => {
    const mediaList = [];
    if (media) {
      const messageMedia = MediaUtils.CovertToMessageMedia(media, {
        title: props.stepConfig.shareMessageModal.mediaTitle,
      });
      if (messageMedia) mediaList.push(messageMedia);
    }

    const resp = await call({
      exGroupId: props.binding.slackChannel?.id ?? '',
      text,
      mediaList,
    });
    if (resp) {
      props.updateBinding({
        sharedMessage: resp.data.message,
      });
      props.setStep('bind-done');
    }
  };

  if (!props.stepConfig.shareMessageModal.enabled) {
    props.setStep('bind-done');
    return null;
  }

  return (
    <ModalLayout
      title={`Announce the news on Slack in #${props.binding.channel?.name}`}
      onClose={props.onClose}
    >
      <div className='mt-7.5 w-full'>
        <SlackMessageInput
          text={text}
          onTextChange={(v) => setText(v)}
          media={media}
          onMediaChange={(v) => setMedia(v)}
          onMediaReset={() =>
            setMedia(props.stepConfig.shareMessageModal.attachedMedia)
          }
        />
      </div>
      <div className='mt-5 w-full text-left'>
        Here's how your message will appear
      </div>
      <div className='mt-4 w-full'>
        <SlackMessagePreview
          text={text}
          media={media}
          className='h-100 overflow-auto scrollbar'
        />
      </div>

      <div className='mt-2 w-full pl-5 text-icon-gray text-sms text-bold'>
        Share to
        <span className='text-primary'> #{props.binding.channel?.name}</span>
      </div>

      <footer className='mt-5 w-full'>
        {error && (
          <div className='mb-1 w-full text-center text-red-002 text-sms'>
            {err2s(error)}
          </div>
        )}
        <div className='w-full flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-45 h-10 flex items-center justify-center'
            onClick={() => props.setStep('bind-done')}
          >
            Skip
          </button>
          <button
            type='button'
            className='btn-primary w-50 h-10 flex items-center justify-center'
            disabled={isRunning || text.length === 0}
            onClick={handleClickShare}
          >
            {isRunning && <Loading containerClassName='mr-2' text='' />}
            Share
          </button>
        </div>
      </footer>
    </ModalLayout>
  );
}

interface SlackChannelBindingModalProps {
  orgId: string;
  program: DtoProgram;
  stepConfig: SlackBindingStepConfig;
  initBinding?: SlackModalBinding;

  onClose: () => void;
  onComplete: (binding: SlackModalBinding) => void;
}

function Dispatcher(
  props: {
    step: SlackBindingModalStep;
  } & ModalSharedProps
) {
  switch (props.step) {
    case 'bind-new':
      return <BindingNewModal {...props} />;
    case 'bind-exist':
      return <BindingExistModal {...props} />;
    case 'bind-private':
      return <BindingPrivateModal {...props} />;
    case 'invite-users':
      return <InviteUserModal {...props} />;
    case 'share-message':
      return <ShareMessageModal {...props} />;
    case 'bind-done':
      return <BindingDoneModal {...props} />;
    default:
      assertExhaustive(props.step);
      return null;
  }
}

function SlackChannelBindingModal(
  props: SlackChannelBindingModalProps
): JSX.Element | null {
  const program = props.program;

  const [step, setStep] = useState<SlackBindingModalStep>(
    props.stepConfig.initStep
  );
  const [binding, setBinding] = useState<SlackModalBinding>(
    props.initBinding ?? {}
  );

  const updateBinding = useLiveCallback(
    (updates: Partial<SlackModalBinding>) => {
      setBinding({
        ...binding,
        ...updates,
      });
    }
  );

  return (
    <Dispatcher
      step={step}
      orgId={props.orgId}
      program={program}
      stepConfig={props.stepConfig}
      setStep={setStep}
      onClose={props.onClose}
      onComplete={props.onComplete}
      binding={binding}
      updateBinding={updateBinding}
    />
  );
}

export interface TriggerSlackChannelBindingModalProps {
  orgId: string;
  program: DtoProgram;
  overrideStepConfig?: SlackBindingStepConfigOverride;
  initBinding?: SlackModalBinding;
  onComplete?: (binding: SlackModalBinding) => Promise<void>;
}

export function useTriggerSlackChannelBindingModal(): (
  props: TriggerSlackChannelBindingModalProps
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return (props: TriggerSlackChannelBindingModalProps) => {
    const stepConfig = ChannelUtils.MakeSlackBindingStepConfig([
      getSlackBindingStepConfigOverride(props.program.type),
      props.overrideStepConfig,
    ]);

    triggerFullScreenModal({
      kind: 'custom',
      containerClassName: 'bg-black bg-opacity-60',
      element: (p) => (
        <SlackChannelBindingModal
          orgId={props.orgId}
          program={props.program}
          stepConfig={stepConfig}
          initBinding={props.initBinding}
          onComplete={async (binding: SlackModalBinding) => {
            p.internalOnConfirm();
            if (props.onComplete) {
              await props.onComplete(binding);
            }
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };
}
