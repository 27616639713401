import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type FormatOptionLabelMeta, type MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type DtoSlackUser } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';

interface UserPickerProps {
  orgId: string;
  users?: DtoSlackUser[];
  placeholder?: string;
  onChange?: (value: DtoSlackUser[]) => void;
  error?: unknown;
}

export function SlackUserPicker(props: UserPickerProps): JSX.Element | null {
  const { placeholder = 'Select a person to invite' } = props;
  const userMap = useInstance(() => new Map<string, DtoSlackUser>());
  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoSlackUser, true>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );
  const [selected, setSelected] = useState<MultiValue<DtoSlackUser> | null>(
    null
  );

  const addToMap = useCallback(
    (users: DtoSlackUser[]) => {
      users.forEach((u) => {
        userMap.set(u.id, u);
      });
    },
    [userMap]
  );

  useEffect(() => {
    if (!props.users) return;
    setSelected(props.users);
    addToMap(props.users);
  }, [props.users, addToMap]);

  useEffect(() => {
    return () => userMap.clear();
  }, [userMap]);

  const loadOptions = async (q: string): Promise<DtoSlackUser[]> => {
    const resp = await apiService.slack.queryUsers({
      type: 'byKeywords',
      keyword: q,
      orgId: props.orgId,
    });
    addToMap(resp.data.users);
    return resp.data.users;
  };

  const handleMultiChange = (options: MultiValue<DtoSlackUser>) => {
    setSelected(options);
    if (!props.onChange) return;
    const users: DtoSlackUser[] = [];
    options.forEach((o) => {
      const user = userMap.get(o.id);
      if (user) {
        users.push(user);
      }
    });
    props.onChange(users);
  };

  const handleFormat = (
    user: DtoSlackUser,
    meta: FormatOptionLabelMeta<DtoSlackUser>
  ): ReactNode => {
    return (
      <div className='w-full flex flex-row justify-between'>
        <span>{user.fullName}</span>
        {meta.context === 'menu' && (
          <span className='text-secondary'>{user.email}</span>
        )}
      </div>
    );
  };

  return (
    <AsyncSelect<DtoSlackUser, true>
      placeholder={placeholder}
      styles={styles}
      cacheOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      className={`h-full m-0 ${props.error ? 'field-error px-0' : ''}`}
      value={selected}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No users matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `#${option.fullName}`}
      formatOptionLabel={handleFormat}
      isMulti
      onChange={handleMultiChange}
    />
  );
}
